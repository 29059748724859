<template>
  <div style="padding: 18px">
    <el-collapse v-model="activeNames">
      <div v-for="(group, index) in formList.list" :key="index">
        <el-collapse-item :name="index">
          <template slot="title">
            <span style="font-size: 14px;font-weight: bold">{{group.name}}</span>
          </template>
          <div style="display: flex;justify-content: space-between;flex-wrap: wrap" v-if="!group.isHidden">
            <div v-for="(item, index) in group.items" :key="index" style="width: 70px;margin: 4px">
              <div class="tool-item" @click="enterItem(item)">
                <i :style="'background:' + item.logo.background" :class="item.logo.icon"></i>
              </div>
              <div class="tool-name">{{item.formName}}</div>
            </div>
            <div v-for="item in 20" style="width: 70px"></div>
          </div>
        </el-collapse-item>
      </div>
    </el-collapse>

<!--    <div v-if="!openItemDl">-->
<!--      <div style="height: 10px"></div>-->
<!--&lt;!&ndash;      <input placeholder="请输入名称！" class="query"/>&ndash;&gt;-->
<!--      <div v-for="(group, index) in formList.list" :key="index">-->
<!--        <div class="group">-->
<!--          <div style="display: flex;justify-content: space-between">-->
<!--            <div style="font-size: 15px;font-weight: bold">{{group.name}}</div>-->
<!--            <div class="el-icon-arrow-right" style="font-size: 14px;font-weight: bold"></div>-->
<!--          </div>-->
<!--          <div style="display: flex;margin-top: 10px;justify-content: space-between;flex-wrap: wrap" v-if="!group.isHidden">-->
<!--            <div v-for="(item, index) in group.items" :key="index" style="width: 70px;margin: 4px">-->
<!--              <div class="tool-item" @click="enterItem(item)">-->
<!--                <i :style="'background:' + item.logo.background" :class="item.logo.icon"></i>-->
<!--              </div>-->
<!--              <div class="tool-name">{{item.formName}}</div>-->
<!--            </div>-->
<!--            <div v-for="item in 20" style="width: 70px"></div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
  </div>
</template>

<script>
import { getFormGroups, startProcess } from "@/api/design";
import SponsorProcess from "@/views/workspace/SponsorProcess";
export default {
  name: "approval",
  components: { SponsorProcess },
  data() {
    return {
      user:sessionStorage.getItem("user"),
      openItemDl: false,
      selectForm: {},
      formItem: {},
      actives: [],
      formList: {
        list: [],
        inputs: "",
        searchResult: [],
      },
      activeNames: [0],
      pending: {
        list: [],
      },
    };
  },
  mounted() {
    this.getGroups();
  },
  methods: {
    getGroups() {
      getFormGroups()
        .then((rsp) => {
          this.formList.list = rsp.data;
          this.formList.list.forEach((group) => {
            this.actives.push(group.name);
          });
          this.groups = rsp.data;
        })
        .catch(() => this.$message.error("获取分组异常"));
    },
    enterItem(item) {
      this.selectForm = item;
      this.openItemDl = true;
      this.$router.push({
        path: "/workspaceMini/applyFlow",
        query: {
          selectForm: item,
        },
      });
    },
    submitForm() {
      const formData = this.$refs.processForm.getFormData();
      const form = this.$refs.processForm.getForm();
      const processUser = this.$refs.processForm.getProcessUser();
      const strUserInfo = sessionStorage.getItem("user");
      this.$refs.processForm.validate((valid) => {
        if (valid) {
          startProcess({
            processDefinitionId: form.processDefinitionId,
            formData,
            processUsers: processUser,
            startUserInfo: JSON.parse(strUserInfo),
          }).then((res) => {
            console.log("startProcess", res);
            this.openItemDl = false;
            this.$message.success("提交成功");
          });
        } else {
          this.$message.warning("请完善表单😥");
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.group{
  box-shadow: 0px 0px 10px rgba(99, 99, 99, 0.1);
  margin: 0px 10px 0 10px;
  padding: 15px;
  border-radius: 10px;
}
.tool-item {
  display: flex;
  justify-content: center;
  border-radius: 5px;
  margin: 5px 5px;
  i {
    padding: 8px;
    border-radius: 8px;
    float: left;
    font-size: 26px;
    color: #ffffff;
    background: #38adff;
    margin-bottom: 2px;
  }
}
.tool-name{
  width: 70px;
  font-size: 12px;
  text-align: center;
  white-space: nowrap; /* 不换行 */
  overflow: hidden; /* 超出部分隐藏 */
  text-overflow: ellipsis; /* 显示省略号 */
}
.query{
  height: 36px;
  border-radius: 10px;
  padding: 4px 12px;
  margin: 16px 10px 8px 10px;
  color: #606266;
  font-size: 13px;
  border: none;
  box-shadow: 0px 0px 10px rgba(99, 99, 99, 0.1);
}



</style>
